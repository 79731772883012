import * as React from "react";

import Home from "./Home";

import { Layout } from "../components/Layout";

const IndexPage = () => {
  return (
    <Layout navbarPosition="absolute">
      <Home path="/" />
    </Layout>
  );
};

export default IndexPage;
